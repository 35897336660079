<template>
    <div>
        <s-toolbar :elevation="1" :add='isEditable' @add='addItem()' with=20 :remove='isEditable' @removed=removeItem()>
            <!--     <template v-slot:options>
                     <s-select class="mt-1" :outlined=false :items='[{value:1,text:"Articulo"},{value:3,text:"Servicio"}]' v-model=orderLocal.TypeOrder />
                 </template>-->
        </s-toolbar>
        <v-data-table class="pt-1" dense :headers="headers" :items-per-page=-1 show-select
                      v-model="selected" :items="items" item-key="Line" :height="300" hide-default-footer single-select>
            <template v-for="p in headers" v-slot:[getNameSlot(p.value)]="{ item }">
                <s-date label='' :outlined=false v-model=item[p.value] v-if='p.date'></s-date>
                <s-text v-else :decimal=p.decimal :outlined=false v-model=item[p.value] @paste=paste($event,item.Line,p.value) />
            </template>
        </v-data-table>
    </div>

</template>
<script>
    import _sApprovalTransaction from "../../../services/Security/ApprovalTransactionService";
    import _sRequirementDetail from "../../../services/Logistics/RequirementDetailService";
    import _sRequirement from "../../../services/Logistics/RequirementService";
    import _sGeneral from "@/services/HelperService.js";
    import _sOrderPurchase from "../../../services/Logistics/OrderPurchaseService";
    import _sApprovalLevel from "@/services/Security/ApprovalLevelsService";
    import SArticle from '@/components/Utils/Logistics/SArticleSearch.vue';

    export default {
        components: { SArticle },
        props: {

        },
        data: () => ({
            items: [],
            selected: [],
            item: {
                OddDescription: '',
                ArtCode: '',
            },
            selectedTax: false,
            circuits: [],
            message: '',
            processing: false,
            selectedCircuit: [],
            filterLevels: {},
            configLevels: {
                model: {
                    AccID: "int",
                    AlvID: "ID",
                    SecStatus: "boleam",
                    PstID: "int",
                    PstName: "string",
                },
                service: _sApprovalLevel,
                headers: [
                    { text: "Cargo", value: "PstName" },
                    { text: "Nivel", value: "TypeLevelText" },
                ],
            },
            optionApproved: false, dateOrder: null,
            selectedSAP: [], transactionApproved: null,
            tax: null,
            dialogCircuit: false,

            orderLocal: { TypeOrder: null }, dialogAdd: false,
            itemsConcept: [],
            itemsAccount: [],
            AcoID: null,
            ConID: null,
            ConName: null,
            SupID: "",
            Supplier: null,
            itemsSAP: [], itemsRemove: [],
            dialogLoad: false,
            Attach1: null, Attach2: null, Attach3: null, Attach4: null,
            headers: [
                { text: "Line", value: "Line", sortable: false, },
                { text: "Descripción", value: "OddDescription", sortable: false, width: 400, },
                { text: "Cuenta Mayor", value: "AcoName", width: 100, sortable: false },
                { text: "Prc. U.", value: "OddUnitPrice", sortable: false, width: 70, decimal: true },
                { text: "Impuesto", value: "TaxCode", sortable: false, width: 60 },
                { text: "(%)Imp", value: "OddTaxPercentage", sortable: false, width: 70, decimal: true },
                // { text: "SubTotal", value: "OddAmount", sortable: false, width: 70, align: 'right' },
                { text: "Centro Costo", value: "CceName", sortable: false, width: 70 },
                { text: "Linea de Negocio", value: "BslName", sortable: false, width: 70, },
                { text: "Tipo Costo", value: "TypeCostName", sortable: false, width: 70, },
                { text: "Proyecto", value: "PrjName", sortable: false, width: 70 },
                { text: "Comentario", value: "OddObservation", sortable: false, width: 400, },
            ],
        }),
        computed: {
            isEditable() {
                return (this.orderLocal.OrdStatus == 3 && this.$fun.getUserID() == this.orderLocal.UsrCreateID) || this.orderLocal.OrdID == null
            }
        },
        methods: {
            removeItem() {
                if (this.selected.length == 1)
                    this.items = this.items.filter(x => x.Line != this.selected[0].Line);
                for (let i = 0; i < this.items.length; i++) {
                    this.items[i].Line = i + 1;
                }
            },
            paste(event, index, header) {
                // this.$emit("paste")
                event.preventDefault();
                let indexHeader = this.headers.map(x => x.value).indexOf(header);
                let i = 0;
                let j = indexHeader;
                event.clipboardData.getData('text/plain').split("\n").forEach(row => {
                    if (row.trim() == '') return;
                    if (index + i > this.items.length) this.items.push({ Line: index + i });
                    row.split("\t").forEach(col => {
                        if (this.headers.length > j) {
                            this.items[index - 1 + i][this.headers[j].value] = col;
                        }
                        j = j + 1;
                    });
                    j = indexHeader;
                    i = i + 1;
                });
            },
            getNameSlot(p) {
                return "item." + p;
            },

            addItem() {
                this.item.Line = this.items.length + 1;
                this.items.push({ ...this.item });

            },

        },
    };
</script>
